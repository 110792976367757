/* PlayfairDisplay */
@font-face {
	font-family: 'PlayfairDisplay-Regular';
	src: url('/src/assets/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'PlayfairDisplay-Medium';
	src: url('/src/assets/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'PlayfairDisplay-Black';
	src: url('/src/assets/fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'PlayfairDisplay-Bold';
	src: url('/src/assets/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'PlayfairDisplay-ExtraBold';
	src: url('/src/assets/fonts/PlayfairDisplay/PlayfairDisplay-ExtraBold.ttf') format('truetype');
	font-display: swap;
}

/* Mukta */
@font-face {
	font-family: 'Mukta-Light';
	src: url('/src/assets/fonts/Mukta/Mukta-Light.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Mukta-Regular';
	src: url('/src/assets/fonts/Mukta/Mukta-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Mukta-Medium';
	src: url('/src/assets/fonts/Mukta/Mukta-Medium.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Mukta-SemiBold';
	src: url('/src/assets/fonts/Mukta/Mukta-SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Mukta-Bold';
	src: url('/src/assets/fonts/Mukta/Mukta-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Mukta-ExtraBold';
	src: url('/src/assets/fonts/Mukta/Mukta-ExtraBold.ttf') format('truetype');
	font-display: swap;
}

.hero-fs {
	font-family: 'Mukta-ExtraBold';
	font-size: 6rem;
}

@media (max-width: 700px) {
	.hero-fs {
		font-size: 4.2rem;
	}
}

.project-fs {
	font-family: 'PlayfairDisplay-Bold';
	font-size: 5.5rem;
}

@media (max-width: 700px) {
	.project-fs {
		font-size: 2.5rem;
	}
}

/* 1 */
.primary-ft {
	font-family: 'Mukta-Regular';
}

.secondary-ft {
	font-family: 'PlayfairDisplay-Regular';
}

.s1 {
	font-size: 1.8rem;
}

@media (max-width: 700px) {
	.s1 {
		font-size: 1.2rem;
	}
}

.s2 {
	font-size: 0.8rem;
}

