@font-face {
  font-family: PlayfairDisplay-Regular;
  src: url("PlayfairDisplay-Regular.8d0db587.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-Medium;
  src: url("PlayfairDisplay-Medium.6f240b6b.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-Black;
  src: url("PlayfairDisplay-Black.52caeb48.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-Bold;
  src: url("PlayfairDisplay-Bold.af6d3698.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: PlayfairDisplay-ExtraBold;
  src: url("PlayfairDisplay-ExtraBold.d6bae081.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-Light;
  src: url("Mukta-Light.f5d2fd96.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-Regular;
  src: url("Mukta-Regular.bc69f469.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-Medium;
  src: url("Mukta-Medium.cc6e1ad2.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-SemiBold;
  src: url("Mukta-SemiBold.5b20ae58.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-Bold;
  src: url("Mukta-Bold.6a8dcdc3.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Mukta-ExtraBold;
  src: url("Mukta-ExtraBold.c2656a91.ttf") format("truetype");
  font-display: swap;
}

.hero-fs {
  font-family: Mukta-ExtraBold;
  font-size: 6rem;
}

@media (max-width: 700px) {
  .hero-fs {
    font-size: 4.2rem;
  }
}

.project-fs {
  font-family: PlayfairDisplay-Bold;
  font-size: 5.5rem;
}

@media (max-width: 700px) {
  .project-fs {
    font-size: 2.5rem;
  }
}

.primary-ft {
  font-family: Mukta-Regular;
}

.secondary-ft {
  font-family: PlayfairDisplay-Regular;
}

.s1 {
  font-size: 1.8rem;
}

@media (max-width: 700px) {
  .s1 {
    font-size: 1.2rem;
  }
}

.s2 {
  font-size: .8rem;
}

/*# sourceMappingURL=index.28a22a39.css.map */
